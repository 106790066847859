import { call, put, spawn, takeLatest } from 'redux-saga/effects';

import controlledCall from 'utils/services/controlledSaga';
import { asQuery } from 'utils/functions';
import apiRequest, { get } from 'utils/api/api';
import { appActions, SET_ERROR } from 'commons/reducer/commons.reducer';
import { history } from 'utils/history';
import { defaultQueries } from 'utils/helpers/helper';
import { AUCTIONS_MANAGER, auctionsManagerActions } from '../reducer/auctionManager.reducer';

function* initialQuery() {
  yield takeLatest(AUCTIONS_MANAGER.INITIAL_QUERY, function* fetchInitialQuery(action) {
    yield put(appActions.setLoader('auctions-manager', true));
    const result = yield controlledCall(
      get,
      `api/v1/auctions/manager?${asQuery({ auctionId: action.auctionId })}`,
      undefined,
      auctionsManagerActions.initialQuerySuccess,
    );
    if (result?.error) {
      yield call(() => history.replace('/'));
    }

    yield put(appActions.setLoader('auctions-manager', false));
  });
}

function* editAuction() {
  yield takeLatest(AUCTIONS_MANAGER.EDIT_AUCTION, function* fetchEditAuction(action) {
    yield put(appActions.setLoader('auctions-manager', true));
    const data = new FormData();

    const baseNames = [];
    const copiedBases = [];

    action.mandatesControls.mandates.forEach((mandate) => {
      if (mandate.bases) {
        // Is update in dialog
        Object.values(mandate.bases).forEach((base) => {
          if (base.copied) {
            copiedBases.push(base);
          } else if (base.documentUrl) {
            if (!base.pristine) {
              baseNames.push({
                name: base.fileName,
                id: base.id,
                file: base.file,
                baseFileName: base.file ? `${mandate.sellerId}&&${base.file.name}` : null,
              });

              if (base.file) {
                data.append('mandatesFiles[]', base.file, `${mandate.sellerId}&&${base.file.name}`);
              }
            }
          } else {
            baseNames.push({
              name: base.fileName,
              baseFileName: `${mandate.sellerId}&&${base.file.name}`,
            });
            data.append('mandatesFiles[]', base.file, `${mandate.sellerId}&&${base.file.name}`);
          }
        });
      }
    });

    let i;
    for (i = 0; i < action.contactsControls.contactIds.length; i += 1) {
      data.append('contacts[]', action.contactsControls.contactIds[i]);
    }
    for (i = 0; i < action.mandatesControls.mandates.length; i += 1) {
      data.append('mandates[]', JSON.stringify(action.mandatesControls.mandates[i]));
    }
    // for (i = 0; i < action.mandatesControls.editedMandates.length; i++) {
    //   data.append('editedMandates[]', JSON.stringify(action.mandatesControls.editedMandates[i]));
    // }
    // for (i = 0; i < action.mandatesControls.mandatesRemoved.length; i++) {
    //   data.append('mandatesRemoved[]', JSON.stringify(action.mandatesControls.mandatesRemoved[i]));
    // }
    // for (i = 0; i < action.mandatesControls.documentsRemoved.length; i++) {
    //   data.append('documentsRemoved[]', JSON.stringify(action.mandatesControls.documentsRemoved[i]));
    // }

    data.append('documentTypeCode', action.controls.documentTypeCode);
    data.append('inscription', action.controls.inscription);
    data.append('displayLotNumber', action.controls.displayLotNumber);
    data.append('stateId', action.controls.state);
    data.append('auctionTypeId', action.controls.auctionTypes);
    data.append('unitId', action.controls.unitId);
    data.append('place', action.controls.place);
    data.append('modality', action.controls.modality);
    data.append('date', action.controls.date);
    data.append('name', action.controls.name?.toUpperCase());
    data.append('guaranteeAmount', action.controls.guaranteeAmount);
    data.append('guaranteePercentage', action.controls.guaranteePercentage);
    data.append('baseNames', JSON.stringify(baseNames));
    data.append('copiedBases', JSON.stringify(copiedBases));
    data.append('baseIdsRemoved', JSON.stringify(action.mandatesControls.baseIdsRemoved || []));
    data.append('principal', action.controls.principal);
    data.append('paymentMethods', JSON.stringify(action.controls.paymentMethods));
    data.append('featured', JSON.stringify(action.controls.featured));
    if (action.controls.image) {
      data.append('image', action.controls.image);
    }

    const result = yield apiRequest(
      `api/v1/auctions/${action.controls.auctionId}`,
      {
        method: 'put',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      },
      false,
    );

    yield put(appActions.setLoader('auctions-manager', false));

    if (!result?.error) {
      yield put(appActions.setSuccess('Se ha editado el remate exitosamente'));
      yield put(auctionsManagerActions.editAuctionSuccess(result));
      // yield call(() => history.replace('/auctions'));
      const complement =
        action.controls.areaId.toString() === '1'
          ? defaultQueries.fortinures.auctions
          : defaultQueries.properties.auctions;
      yield call(() => history.replace(`/auctions${complement}`));
    } else {
      yield put(appActions.setWarning(result.errorMsg));
      yield put(auctionsManagerActions.addMandates(action.mandatesControls.mandatesRemoved));
    }
  });
}

function* saveAuction() {
  yield takeLatest(AUCTIONS_MANAGER.CREATE_AUCTION, function* fetchSaveAuction(action) {
    yield put(appActions.setLoader('auctions-manager', true));
    const data = new FormData();

    // action.mandatesControls.mandates.forEach(m => {
    //   data.append('mandatesFiles[]', m.file, m.file.name);
    // });
    const baseNames = [];
    action.mandatesControls.mandates.forEach((mandate) => {
      Object.values(mandate.bases).forEach((base) => {
        baseNames.push({
          name: base.fileName,
          baseFileName: `${mandate.sellerId}&&${base.file.name}`,
        });
        data.append('mandatesFiles[]', base.file, `${mandate.sellerId}&&${base.file.name}`);
      });
    });

    let i;
    for (i = 0; i < action.contactsControls.contactIds.length; i += 1) {
      data.append('contacts[]', action.contactsControls.contactIds[i]);
    }
    for (i = 0; i < action.mandatesControls.mandates.length; i += 1) {
      data.append('mandates[]', JSON.stringify(action.mandatesControls.mandates[i]));
    }
    data.append('documentTypeCode', action.controls.documentTypeCode);
    data.append('inscription', action.controls.inscription);
    data.append('displayLotNumber', action.controls.displayLotNumber);
    data.append('stateId', action.controls.state);
    data.append('auctionTypeId', action.controls.auctionTypes);
    data.append('unitId', action.controls.state);
    data.append('place', action.controls.place);
    data.append('date', action.controls.date);
    data.append('name', action.controls.name?.toUpperCase());
    data.append('guaranteeAmount', action.controls.guaranteeAmount);
    data.append('guaranteePercentage', action.controls.guaranteePercentage);
    data.append('baseNames', JSON.stringify(baseNames));
    data.append('principal', action.controls.principal);
    data.append('paymentMethods', JSON.stringify(action.controls.paymentMethods));
    if (action.controls.image) {
      data.append('image', action.controls.image);
    }
    data.append('featured', JSON.stringify(action.controls.featured));

    const response = yield apiRequest(
      'api/v1/auctions/',
      {
        method: 'post',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      },
      false,
    );

    yield put(appActions.setLoader('auctions-manager', false));

    if (!response.error) {
      yield put(appActions.setSuccess('Se ha creado el remate exitosamente'));
      const complement =
        action.areaId.toString() === '1'
          ? defaultQueries.fortinures.auctions
          : defaultQueries.properties.auctions;
      yield call(() => history.replace(`/auctions${complement}`));
    } else {
      yield put({ type: SET_ERROR, msg: response.errorMsg });
    }
  });
}

export default function* auctionManagerSaga() {
  yield spawn(initialQuery);
  yield spawn(saveAuction);
  yield spawn(editAuction);
}
