/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
import { useTheme } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SideBar from 'commons/components/SideBar/SideBar';

import { useCallback, useEffect, useRef, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import APIcon from '../../../../commons/assets/icons/APBlanco.svg';
import CamposIcon from '../../../../commons/assets/icons/CamposBlanco.svg';
import PropiedadesIcon from '../../../../commons/assets/icons/PropiedadesBlanco.svg';
import RematesIcon from '../../../../commons/assets/icons/RematesBlanco.svg';
import LogoTattersall from '../../../../commons/assets/LOGOS GDA.png';
import fontStyles from '../styles/fontStyles';
import ContactModal from './ContactModal';
// import { externalLink } from 'utils/helpers/helper';
// import { getItemInStorage } from 'utils/functions';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// eslint-disable-next-line react/function-component-definition
const MyNavbar = ({ layoutProps }) => {
  const theme = useTheme();
  // Detect if screen size is extra small (xs) or small (sm)

  const isSmallScreen = useMediaQuery('(max-width:1300px)');

  const [isScrolled, setIsScrolled] = useState(false);

  const user = useSelector((state) => state.user.controls.user);
  const { globals } = useSelector((state) => state.app);
  const { propertiesIsLive, furnituresIsLive } = globals;
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 45); // Adjust threshold as needed
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const [isPopperOpen, setPopperOpen] = useState(false); // Popper state

  //SideBar functions

  const [openDrawer, setOpenDrawer] = useState(false);

  const anchorRef = useRef(null);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleItemClick = useCallback((module) => {
    history.push(module);
    toggleDrawer();
  }, []);

  //Popper functions

  const handleToggle = () => {
    setPopperOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setPopperOpen(false);
  };

  //Contact Modal functions

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const navLinks = [
    {
      href: `${process.env.REACT_APP_PROPIEDADES_URL}`,
      icon: PropiedadesIcon,
      alt: 'Propiedades Icon',
      text: 'PROPIEDADES',
    },
    {
      href: `${process.env.REACT_APP_CAMPOS_URL}`,
      icon: CamposIcon,
      alt: 'Campos Icon',
      text: 'CAMPOS',
    },
    {
      href: `${process.env.REACT_APP_ARRIENDOS_URL}`,
      icon: APIcon,
      alt: 'AP Icon',
      text: 'ADMINISTRACIÓN Y ARRIENDOS',
    },
    {
      href: `${process.env.REACT_APP_REMATES_URL}`,
      icon: RematesIcon,
      alt: 'GDA Icon',
      text: 'MAQUINARIAS / EQUIPOS / VEHÍCULOS',
      customClass: 'nav-link-responsive-last',
      customStyle: { fontWeight: 'bold', color: '#ffffff', padding: 0 },
    },
  ];
  // const history = useHistory();
  // const externalRolLink = globals.rolExternalLink;
  // const handleLiveButtonClick = () => {
  //   const userLog = layoutProps.user;
  //   if (Object.keys(userLog).length > 0) {
  //     return window.open(externalLink(getItemInStorage('user'), externalRolLink));
  //   } else {
  //     return history.push('/login');
  //   }
  // };
  const menuItems = [
    {
      title: '¿Quiénes somos?',
      href: 'https://storage.googleapis.com/gda-assets/Video04_TattersallGDA_v2.mp4',
      onClick: null,
      anchorRef: null,
    },
    { title: 'Remates', href: '', onClick: handleToggle, anchorRef: anchorRef },
    { title: 'Contacto', href: '#home', onClick: handleModalOpen, anchorRef: null },
    // { title: '', href: '', onClick: handleLiveButtonClick, anchorRef: null },
  ];

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isScrolled ? (
        <Navbar
          style={{
            backgroundColor: '#76a4cd',
            position: 'fixed',
            height: 48,
            width: '100%',
            zIndex: 1000,
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              height: '100%',
            }}>
            {navLinks.map((link, index, array) => (
              <>
                <Box
                  sx={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    gap: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    window.location.href = link.href;
                  }}>
                  <Box>
                    <img
                      src={link.icon}
                      alt={link.alt}
                      style={{
                        width: '29px',
                        height: '29px',
                        backgroundColor: '#76a4cd', // Aplica un fondo solo si es necesario
                      }}
                    />
                  </Box>
                  {!isSmallScreen && (
                    <Box>
                      <span style={fontStyles.navbarText}>{link.text}</span>
                    </Box>
                  )}
                </Box>
                {index < array.length - 1 ? (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      backgroundColor: 'white', // Cambia el color de la línea
                      width: '1px', // Define el grosor
                      height: 20, // Define la altura del Divider
                      alignSelf: 'center',
                    }}
                  />
                ) : null}
              </>
            ))}
          </Box>
        </Navbar>
      ) : (
        <Navbar
          style={{
            backgroundColor: isScrolled ? '#dadfea' : '#ffffff',
            position: 'fixed',
            zIndex: 1000,
            width: '100%',
          }}>
          <Nav className="w-100 justify-content-center">
            {/* Logo Section */}
            <Box container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
              <Nav.Link href="/" className="nav-link-responsive-grey">
                <Box
                  component="img"
                  src={LogoTattersall}
                  alt="Logo"
                  sx={{
                    width: 350,
                    margin: '0 auto',
                    display: 'block',
                    p: 2,
                    [theme.breakpoints.down(1300)]: {
                      width: 250,
                    },
                  }}
                />
              </Nav.Link>
            </Box>
            {/* Navigation Links */}
            <Box
              sx={{
                display: 'flex',
              }}>
              {/* Icon Button (Aligned Left) */}
              <Box>
                <IconButton onClick={toggleDrawer}>
                  <MenuIcon
                    fontSize="large"
                    sx={{ width: '30px', height: '30px', color: '#707070' }}
                  />
                </IconButton>
              </Box>

              {/* Centered Navigation Links */}
              <Box
                sx={{
                  display: 'flex',
                  gap: 8,
                  flex: 1,
                  justifyContent: 'center',
                  marginLeft: -8,
                  [theme.breakpoints.down(475)]: {
                    marginLeft: 0,
                    gap: 2,
                  },
                }}>
                {menuItems.map((item, index) => (
                  <Box key={index} item xs={3} sm={2}>
                    <Nav.Link
                      href={item.href}
                      ref={item.anchorRef}
                      style={{ textDecoration: 'none' }}
                      onClick={item.onClick}>
                      <Box component="span" sx={fontStyles.navbarTextGrey}>
                        <Typography
                          sx={{
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '15px',
                            lineHeight: '30px',
                            fontFamily: 'Trebuchet MS',
                            whiteSpace: 'nowrap',
                            color: '#67757D',
                          }}>
                          {item.title}
                        </Typography>
                      </Box>
                    </Nav.Link>
                  </Box>
                ))}
              </Box>
            </Box>
          </Nav>
          <ContactModal open={isModalOpen} handleClose={handleModalClose} />
          <Popper
            open={isPopperOpen}
            anchorEl={anchorRef.current}
            placement="bottom"
            transition
            disablePortal
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 0], // Adjust horizontal and vertical offset
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  boundary: 'viewport',
                },
              },
            ]}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={isPopperOpen}
                      id="composition-menu"
                      aria-labelledby="composition-button">
                      <MenuItem
                        component="a"
                        href="https://tattersallgda.cl/auctions?areaId=1&auctionCode=ALL">
                        <Typography
                          sx={{
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '15px',
                            lineHeight: '30px',
                            fontFamily: 'Trebuchet MS',
                            whiteSpace: 'nowrap',
                            color: '#67757D',
                          }}>
                          Vehículos, Maquinarias y Equipos
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        component="a"
                        href="https://tattersallgda.cl/auctions?areaId=2&auctionCode=ALL">
                        <Typography
                          sx={{
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '15px',
                            lineHeight: '30px',
                            fontFamily: 'Trebuchet MS',
                            whiteSpace: 'nowrap',
                            color: '#67757D',
                          }}>
                          Propiedades y Campos
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <SideBar
            {...layoutProps}
            isOpen={openDrawer}
            toggleDrawer={toggleDrawer}
            globals={globals}
            handleItemClick={handleItemClick}
            actions={layoutProps.actions}
            balance={user?.balance}
            propertyBalance={user?.propertyBalance}
            propertiesIsLive={propertiesIsLive}
            furnituresIsLive={furnituresIsLive}
          />
        </Navbar>
      )}
    </>
  );
};

export default MyNavbar;
